import {
  Badge,
  Box,
  Button,
  Divider,
  Image,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export interface EventCardProps {
  imageURL?: string;
  title: string;
  location: string;
  date: string;
  price: string;
  blackTags: string[];
  grayTags: string[];
  loading?: boolean;
  blurred?: boolean;
  onSelect?: () => void;
  fastPass?: boolean;
}

const EventCard: React.FC<EventCardProps> = (props: EventCardProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    setImageLoaded(false);
  }, [props.imageURL]);

  return (
    <Box
      height={"max-content"}
      width={"max-content"}
      display={"flex"}
      flexDir={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      overflow={"hidden"}
      borderRadius="2xl"
      cursor="pointer"
      onClick={props.onSelect && !props.blurred ? props.onSelect : undefined}
    >
      {props.blurred && (
        <Badge
          height="max-content"
          borderRadius="full"
          filter={"unset"}
          pos={"absolute"}
          zIndex={10}
          px="2"
          py="0.5"
          variant={"solid"}
          color={"secondary.main"}
          bg={"muted.main"}
          textTransform="none"
          fontSize="13"
          fontWeight={500}
          mr={2}
        >
          Próximamente
        </Badge>
      )}
      <Box
        maxW={["90vw", "xs"]}
        bg={"secondary.main"}
        filter={props.blurred ? "blur(5px)" : "unset"}
      >
        <Skeleton
          startColor="secondary.800"
          endColor="secondary.700"
          overflow={"hidden"}
          isLoaded={!props.loading && imageLoaded}
        >
          <Image
            src={props.imageURL}
            fallback={<Box w={["90vw", "xs"]} style={{ aspectRatio: 2.2 }} />}
            overflow={"hidden"}
            borderTopRadius={"2xl"}
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageLoaded(true)}
            style={{ aspectRatio: 2.2 }}
            w={["90vw", "xs"]}
            userSelect={"none"}
          />
        </Skeleton>

        <Box p="4">
          <Box display="flex" mb="3">
            <Skeleton
              startColor="secondary.800"
              endColor="secondary.700"
              isLoaded={!props.loading}
              borderRadius="xl"
            >
              <Badge
                variant={"outline"}
                borderRadius="md"
                px="2"
                py="0.5"
                colorScheme={"primary"}
                color={"primary.main"}
                // textTransform="none"
                fontSize="sm"
                fontWeight="600"
                autoCapitalize="false"
              >
                {props.date}
              </Badge>
            </Skeleton>
          </Box>

          <Skeleton
            startColor="secondary.800"
            endColor="secondary.700"
            isLoaded={!props.loading}
            borderRadius="xl"
          >
            <Box
              mt="1"
              fontWeight="600"
              as="h4"
              lineHeight="tight"
              noOfLines={1}
            >
              <Text color={"secondary.contrast"}>{props.title}</Text>
            </Box>
          </Skeleton>

          <Skeleton
            startColor="secondary.800"
            endColor="secondary.700"
            isLoaded={!props.loading}
            borderRadius="xl"
          >
            <Box mt="1" fontWeight={400} fontSize={14} noOfLines={1}>
              <Text color={"secondary.contrast"}>{props.location}</Text>
            </Box>
          </Skeleton>

          <Divider my={3}></Divider>

          <Box
            display={"flex"}
            flexDir={"row"}
            justifyContent={"space-between"}
            maxW={"100%"}
          >
            <Box
              maxW={"50%"}
              display={"flex"}
              flexDir={"row"}
              flexWrap={"wrap"}
            >
              {props.blackTags.map((tag, index) => (
                <Badge
                  maxWidth={"100%"}
                  textOverflow={"ellipsis"}
                  height="max-content"
                  borderRadius="md"
                  px="2"
                  py="0.5"
                  bg={"backgroundColor"}
                  color={"muted.main"}
                  textTransform="none"
                  fontSize="sm"
                  fontWeight={500}
                  mr={2}
                  key={"badge-" + index}
                  overflow={"hidden"}
                >
                  {tag}
                </Badge>
              ))}
              {props.grayTags.map((tag, index) => (
                <Badge
                  maxWidth={"100%"}
                  height="max-content"
                  borderRadius="md"
                  px="2"
                  py="0.5"
                  variant={"solid"}
                  color={"secondary.main"}
                  bg={"muted.main"}
                  textTransform="none"
                  fontSize="13"
                  fontWeight={500}
                  mr={2}
                  key={"other-badge-" + index}
                >
                  {tag}
                </Badge>
              ))}
            </Box>
            <Skeleton
              startColor="secondary.800"
              endColor="secondary.700"
              isLoaded={!props.loading}
              borderRadius="xl"
              overflow={"hidden"}
            >
              <Box overflow={"hidden"} textAlign={"right"}>
                <Button
                  colorScheme="primary"
                  borderRadius={"full"}
                  size="md"
                  onClick={props.onSelect}
                  disabled={props.blurred}
                >
                  COMPRAR
                </Button>

                <Text
                  fontWeight={400}
                  fontSize={14}
                  color={
                    props?.price && props.price !== "0"
                      ? "secondary.contrast"
                      : "transparent"
                  }
                >
                  {props?.price && props.price !== "0"
                    ? "desde $" + props.price
                    : "-"}
                </Text>
              </Box>
            </Skeleton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EventCard;
