import * as React from "react";
import {
  Flex,
  Link,
  Image,
  VStack,
  Text,
  useDisclosure,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { colors } from "../theme/colors";
import { AiOutlineMail } from "react-icons/ai";
import ModalContainer from "./LoginModal";
import { chileTiketLogo } from "../resources";
import { FacebookIcon } from "./Icons/FacebookIcon";

export const Footer = ({simple = false}: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    !simple ?
    <Flex
      w="full"
      as="footer"
      flexDir={["column", "column", "row"]}
      alignItems="flex-start"
      justifyContent="flex-end"
      borderTop="1px"
      borderColor="gray.800"
      mt={6}
      px="4%"
      py="10"
    >
      <Flex
        h="full"
        maxW={150}
        as={Link}
        to="/"
        justify="left"
        zIndex={1}
        mr="auto"
      >
        <Image objectFit={"contain"} src={chileTiketLogo} />
      </Flex>
      <Stack
        flexDir="row"
        w="full"
        justifyContent={"space-around"}
        flexWrap={["wrap", "wrap", "nowrap"]}
      >
        <VStack
          mt={[4, 4, 0]}
          w={["50%", "50%", "20%"]}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Text
            color={colors.primary.main}
            fontWeight="semibold"
            mb={[0, 0, 2]}
          >
            Info
          </Text>
          <Link fontSize="small" fontWeight="semibold" href="/">
            Inicio
          </Link>
          <Link
            fontSize="small"
            fontWeight="semibold"
            href="https://clientes.chiletiket.com/#somos"
            target="_blank"
          >
            Acerca de ChileTiket
          </Link>
          <Link fontSize="small" fontWeight="semibold" href="/terms">
            Términos y condiciones
          </Link>
          <Link fontSize="small" fontWeight="semibold" href="/frequents">
            Preguntas frecuentes
          </Link>
        </VStack>
        <VStack
          mt={[4, 4, 0]}
          w={["50%", "50%", "20%"]}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Text
            color={colors.primary.main}
            fontWeight="semibold"
            mb={[0, 0, 2]}
          >
            Links
          </Text>
          <Link fontSize="small" fontWeight="semibold" onClick={onOpen}>
            Inicio de sesión
          </Link>
          <Link
            fontSize="small"
            fontWeight="semibold"
            href="https://clientes.chiletiket.com/#marcablanca"
            target="_blank"
          >
            Crea tu propia Tiketera
          </Link>
          <Link
            fontSize="small"
            fontWeight="semibold"
            href="https://clientes.chiletiket.com/#servicios"
            target="_blank"
          >
            ¿Tienes un evento?
          </Link>
          <Link
            fontSize="small"
            fontWeight="semibold"
            href="https://clientes.chiletiket.com/#pruductos-linea"
            target="_blank"
          >
            ¿Quieres tu propia tienda?
          </Link>
          <Link
            fontSize="small"
            fontWeight="semibold"
            href="https://clientes.chiletiket.com/#contacto"
            target="_blank"
          >
            Contacto
          </Link>
        </VStack>
        <VStack
          mt={[4, 4, 0]}
          w={["full", "full", "20%"]}
          justifyContent="flex-start"
          h="auto"
          alignItems="flex-start"
        >
          <Text
            color={colors.primary.main}
            fontWeight="semibold"
            mb={[0, 0, 2]}
          >
            Contacto
          </Text>
          <HStack
            display={["none", "none", "flex"]}
            sx={{ marginTop: "20px!important", gap: "10px" }}
          >
            <Link
              href="https://www.facebook.com/share/nEqtxDvpV1xXvewW/?mibextid=LQQJ4d"
              target="_blank"
            >
              <FacebookIcon h={20}/>
            </Link>
            <Link
              href="https://www.instagram.com/chiletiket/?igsh=ZnhibmUwMWYycWE0"
              target="_blank"
            >
              <FaInstagram size={32} />
            </Link>
            <Link href="https://wa.me/56945950920" target="_blank">
              <FaWhatsapp size={32} />
            </Link>
            <Link href="mailto:ventas@chiletiket.com" target="_blank">
              <AiOutlineMail size={32} />
            </Link>
          </HStack>
          <HStack
            display={["flex", "flex", "none"]}
            sx={{ marginTop: "0.5rem!important" }}
            gap="5px"
          >
            <Link
              href="https://www.facebook.com/share/nEqtxDvpV1xXvewW/?mibextid=LQQJ4d"
              target="_blank"
            >
              <FacebookIcon h={22}/>
            </Link>
            <Link
              href="https://www.instagram.com/chiletiket/?igsh=ZnhibmUwMWYycWE0"
              target="_blank"
            >
              <FaInstagram size={26} />
            </Link>
            <Link href="https://wa.me/56945950920" target="_blank">
              <FaWhatsapp size={26} />
            </Link>
            <Link href="mailto:ventas@chiletiket.com" target="_blank">
              <AiOutlineMail size={28} />
            </Link>
          </HStack>
        </VStack>
      </Stack>
      <ModalContainer isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </Flex>
    : 
    <div
      style={{display: "flex", alignItems: "center", justifyContent: "center", padding: "24px"}}
    >
        <Text
        color={"#FFFFFF"}
        fontWeight="500"
        fontSize={"13px"}
        textAlign={"center"}
        noOfLines={2}
      >
        Copyright © 2024 ChileTicket. Todos los derechos reservados
      </Text>
    </div>
  );
};
