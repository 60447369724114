import { useContext } from "react";
import { AuthContext } from "../../../providers/authContext";
import { AxiosContext } from "../../../providers/axiosContext";
import { AsyncStore } from "../../../utils/createAsyncStore";
import {
  EventDTO,
  EventTicketVariants,
  TicketVariantCreateDTO,
} from "../../events/dto";
import {
  AdminListDTO,
  AttendeeCountDTO,
  EventAttendeesDTO,
  PaginationOptionsDTO,
} from "./dto";
import { useUserEventsAsyncStore } from "./userEventsStore";
import { getProducer } from "../../../utils/getProducer";
import { zonedTimeToUtc } from "date-fns-tz";
import { TIME_ZONE } from "../../../utils/dateFormatters";

export interface UserEventsService {
  getUserEventList: () => Promise<EventDTO[]>;
  getUserEvent: (eventId: string) => Promise<EventDTO>;
  getTicketVariants: (eventId: string) => Promise<EventTicketVariants>;
  userEventsStore: AsyncStore<EventDTO[]>;
  getTicketPDF: (id: string) => Promise<Buffer>;
  getAttendees: (
    eventId: string,
    functionId: string,
    paginationOptions?: PaginationOptionsDTO
  ) => Promise<EventAttendeesDTO>;
  getAdmins: (eventId: string) => Promise<AdminListDTO>;
  getFunctionToken: (eventId: string, functionId: string) => Promise<string[]>;
  getAttendeesCount: (
    eventId: string,
    functionId: string
  ) => Promise<AttendeeCountDTO>;
  updateAdmins: (
    users: any[],
    action: string,
    eventId: string
  ) => Promise<any[]>;
  editEvent: (event: any) => Promise<any>;
  addAdmin: (email: string, role: string, eventId: string) => Promise<any>;
  updateAssistance: (
    functionId: string,
    transactionId: string,
    currentState: boolean
  ) => Promise<any>;
  getFunctionReport: (id: string) => Promise<any>;
  createNewTicketVariant: (
    functionId: string,
    eventId: string,
    producer: string,
    newTicketVariant: TicketVariantCreateDTO
  ) => Promise<any>;
  editTicketVariant: (
    functionId: string,
    eventId: string,
    producer: string,
    ticketVariantId: string,
    newTicketVariant: TicketVariantCreateDTO
  ) => Promise<any>;
}

const getURL = (eventId: string, functionId: string) =>
  functionId === "all" ? "event/" + eventId : "function/" + functionId;

export const useUserEventsService = (): UserEventsService => {
  const axiosContext = useContext(AxiosContext);

  const userEventsStore = useUserEventsAsyncStore();

  const authContext = useContext(AuthContext);

  const producer = getProducer();

  const getUserEventList = async () => {
    const res = await axiosContext.authAxios.get<EventDTO[]>(
      `/events/events/admin`,
      {
        params: { producer },
      }
    );
    return res.data.reverse();
  };

  const getUserEvent = async (eventId: string) => {
    const res = await axiosContext.publicAxios.get<EventDTO>(
      `/events/events/${eventId}`
    );
    return res.data;
  };

  const getAttendees = async (
    eventId: string,
    functionId: string,
    paginationOptions?: PaginationOptionsDTO
  ) => {
    const res = await axiosContext.authAxios.get(
      `/scanner/tickets/${getURL(eventId, functionId)}/assistantList`,
      {
        headers: {
          Authorization: `Bearer ${authContext.getAccessToken()}`,
          "not-cached-request": "not-cached-request",
        },
        params: {
          ...paginationOptions,
          full: true,
        },
      }
    );
    return res.data;
  };

  const getAttendeesCount = async (eventId: string, functionId: string) => {
    const res = await axiosContext.authAxios.get(
      `/scanner/tickets/${getURL(eventId, functionId)}/assistants`
    );
    return res.data;
  };

  const getTicketVariants = async (
    functionId: string
  ): Promise<EventTicketVariants> => {
    const res = await axiosContext.publicAxios.get<EventTicketVariants>(
      `/tickets/ticket-variant/function/${functionId}?full=true`
    );
    return res.data;
  };

  const createNewTicketVariant = async (
    functionId: string,
    eventId: string,
    producer: string,
    newTicketVariant: TicketVariantCreateDTO
  ): Promise<any> => {
    const { sellFinishDatetime, sellStartDatetime, ...resto } =
      newTicketVariant;

    const body = {
      ...resto,
      sellStartDatetime: zonedTimeToUtc(new Date(sellStartDatetime), TIME_ZONE),
      sellFinishDatetime: zonedTimeToUtc(
        new Date(sellFinishDatetime),
        TIME_ZONE
      ),
      function: functionId,
      event: eventId,
      producer,
    };
    const res = await axiosContext.authAxios.post(
      `/tickets/ticket-variant`,
      body
    );
    return res;
  };
  const editTicketVariant = async (
    functionId: string,
    eventId: string,
    producer: string,
    ticketVariantId: string,
    newTicketVariant: TicketVariantCreateDTO
  ): Promise<any> => {
    const expiredTicketResponse = await axiosContext.authAxios.put(
      `/tickets/ticket-variant/${ticketVariantId}`,
      { isExpired: true }
    );

    if (expiredTicketResponse.status === 200) {
      const {
        _id,
        v,
        created,
        createdUser,
        bloqued,
        sellFinishDatetime,
        sellStartDatetime,
        ...resto
      } = newTicketVariant as any;
      const body = {
        ...resto,
        sellStartDatetime: zonedTimeToUtc(
          new Date(sellStartDatetime),
          TIME_ZONE
        ),
        sellFinishDatetime: zonedTimeToUtc(
          new Date(sellFinishDatetime),
          TIME_ZONE
        ),
        function: functionId,
        event: eventId,
        producer,
        bloqued: 0,
      };
      const res = await axiosContext.authAxios.post(
        `/tickets/ticket-variant`,
        body
      );
      return res;
    } else {
      return expiredTicketResponse.data;
    }
  };

  const getTicketPDF = async (id: string): Promise<Buffer> => {
    const res = await axiosContext.authAxios.get("/tickets/pdf/ticket/" + id, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + authContext.getAccessToken(),
        "Content-Type": "application/pdf",
      },
    });
    return res.data;
  };

  const getFunctionToken = async (eventId: string, functionId: string) => {
    const res = await axiosContext.authAxios.get(
      "events/events/" + eventId + "/events-function/" + functionId,
      {
        params: {
          token: true,
        },
      }
    );

    return res.data?.tokens;
  };

  const getAdmins = async (eventId: string) => {
    const res = await axiosContext.authAxios.get(
      `/events/events/admin/${eventId}`
    );
    return res.data;
  };

  const editEvent = async (event: any) => {
    const res = await axiosContext.authAxios.put(
      `/events/events/${event?._id}`,
      event
    );
    console.log("edit event res",res)
    return res.data;
  };

  const updateAdmins = async (
    users: any[],
    action: string,
    eventId: string
  ) => {
    const res = await axiosContext.authAxios.put(
      "events/events/admin/" + eventId,
      users,
      {
        params: {
          action,
        },
      }
    );

    return res.data?.tokens;
  };

  const addAdmin = async (email: string, role: string, eventId: string) => {
    const res = await axiosContext.authAxios.post(
      "events/events/admin/" + eventId,
      {
        email,
        status: "active",
        role,
      }
    );

    return res.data;
  };

  const updateAssistance = async (
    functionId: string,
    transactionId: string,
    currentState: boolean
  ) => {
    const res = await axiosContext.authAxios.post(
      "/scanner/tickets/" +
        functionId +
        (currentState ? "?notUsedTickets=true" : ""),
      [{ transactionId }]
    );
    return res.data;
  };

  const getFunctionReport = async (id: string) => {
    const res = await axiosContext.authAxios.get<EventDTO>(
      `/tickets/reports/sales/${id}`
    );
    return res.data;
  };

  return {
    getUserEventList,
    getUserEvent,
    getTicketVariants,
    userEventsStore,
    createNewTicketVariant,
    getTicketPDF,
    getAttendees,
    getAttendeesCount,
    getAdmins,
    getFunctionToken,
    updateAdmins,
    addAdmin,
    updateAssistance,
    getFunctionReport,
    editTicketVariant,
    editEvent
  };
};
