import React, { useEffect, useState } from "react";
import { Box, Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { colors } from "../../../../theme/colors";
import isValidUrl from "../../../../utils/isValidURL";
import { ticketRejected } from "../../../../resources";
import { formatInTimeZone } from "date-fns-tz";
import { TIME_ZONE } from "../../../../utils/dateFormatters";
import { es } from "date-fns/locale";
import { images } from "../../../../config/images";
import { useToaster } from "../../../../utils/useToaster";
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';
import { getProducer } from "../../../../utils/getProducer";
import { baseUrl } from "../../../../config/envConf";

const ModalFastPass = ({ userEventList, selectedOption, openFastPass, onCloseModal, setSelectedOption, step, setHaveFastPass }: ModalFastPassProps) => {

    const { successToast } = useToaster();
    const [qr, setQr] = useState<any>();

    const onPressNext = () => {
        successToast(
            "¡Fast Pass habilitado!",
            "Ya se pueden comprar tickets en puerta de los eventos con Fast Pass habilitado",
            {
                icon: () => null,
                autoClose: 10000
            }
        )
        setHaveFastPass(true);
        onCloseModal();
    };

    const downloadPDF = async () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4',
        });
    
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
    
        pdf.setFontSize(40);
        pdf.setFont("helvetica", "bold");
        pdf.text("Compra tu entrada", width / 2, 60, { align: "center" });
    
        pdf.setLineWidth(2);
        for (let i = 0; i < width; i += 7) {
            pdf.line(i, 90, i + 4, 90);
        }
    
        pdf.setFillColor(0, 0, 0);
        pdf.circle(0, 90, 3, 'F');
        pdf.circle(width, 90, 3, 'F');
    
        pdf.setLineWidth(0.2);
    
        pdf.setFontSize(32);
        pdf.setFont("helvetica", "normal");
        pdf.text("Escanea el QR", width / 2, 150, { align: "center" });
        pdf.text("para comprar tu ticket", width / 2, 180, { align: "center" });
        
        pdf.addImage(qr, 'PNG', (width - 226) / 2, 210, 226, 226);
    
        const footerWidth = 150;
        const footerHeight = 30;
        pdf.addImage(images.chileTicketFooter, 'PNG', (width - footerWidth) / 2, height - 60, footerWidth, footerHeight);
    
        pdf.save('TicketQR.pdf');
    };

    const generateQr = async (id: string) => {
        let qrImageUrl: any;
        const producer = getProducer();
        if(producer){
            qrImageUrl = await QRCode.toDataURL(`https://${producer}.chiletiket.cl/fastPass`);
        }else{
            qrImageUrl = await QRCode.toDataURL(`${baseUrl}/fastPass`)
        }
        setQr(qrImageUrl);
    };

    useEffect(() => {
        if(userEventList?.length){
            generateQr(userEventList[0]?.owner)
        }
    },[userEventList])

    return (
        <Modal size={"6xl"} isCentered isOpen={openFastPass} onClose={onCloseModal}>
            <ModalOverlay />
            <ModalContent bgColor={colors.muted[800]}>
                <ModalHeader textAlign={"center"} px="32px" py="32px" pb={0}>
                    Fast Pass
                </ModalHeader>
                <ModalCloseButton mr={"24px"} mt={"24px"} />
                <ModalBody
                    px="32px"
                    py="24px"
                    borderTop="1px solid muted.600"
                    borderBottom="1px solid muted.600"
                    justifyContent={"center"}
                >
                    {step === "options" ?

                        <>
                            <Text mt={0} textAlign={"center"} >
                                Permite a las personas comprar entradas en la puerta de tus eventos.
                            </Text>
                            <Text textAlign={"center"} mb={12}>
                                Selecciona el evento donde quieres permitir que se compren tickets en la puerta.
                            </Text>
                            <div style={{ display: "flex", flexDirection: "row", gap: "16px", marginTop: "16px", flexWrap: "wrap", justifyContent: "center" }}>
                                {userEventList && userEventList?.map((event, index) => {
                                    if (index === 3) return null;
                                    return (
                                        <Box
                                            opacity={selectedOption === event?._id ? 1 : .7}
                                            border={selectedOption === event?._id ? "1px solid white" : 0}
                                            borderColor={"white"}
                                            _hover={{ bgColor: "#1c1c1c", cursor: "pointer" }}
                                            backgroundColor={"#141414"}
                                            onClick={() => setSelectedOption(event?._id)}
                                            style={{
                                                width: "291px",
                                                borderRadius: "22px",
                                            }}>
                                            <Image
                                                height={108}
                                                width={"100%"}
                                                borderTopRightRadius={"22px"}
                                                borderTopLeftRadius={"22px"}
                                                src={
                                                    isValidUrl(event.images.card.url)
                                                        ? event.images.card.url
                                                        : ticketRejected
                                                } />
                                            <Stack p={"16px"}>
                                                <Text fontSize={24} fontWeight={"400"}>{event.name}</Text>
                                                <Text fontSize={16} >
                                                    {formatInTimeZone(
                                                        new Date(event.functions[0].datetime),
                                                        TIME_ZONE,
                                                        "PPP",
                                                        {
                                                            locale: es,
                                                        }
                                                    )}
                                                </Text>
                                                <Text fontSize={16}>{event.placeName}</Text>
                                            </Stack>
                                        </Box>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                            <Text mt={0} textAlign={"center"} >
                                Descarga el QR para colocar en la entrada de tu recinto.
                            </Text>
                            <Text textAlign={"center"} mb={12}>
                                Puedes utilizar siempre el mismo QR y sólo actualizar el evento que se puede comprar en puerta por lo que, si ya lo descargaste anterioremente, no es necesario que lo vuelvas a hacer.
                            </Text>
                            <Box borderRadius={"24px"} p={4} gap={4} border={"1px solid #525252"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                                <img src={qr} style={{ width: "277px", height: "277px" }} />
                                <Button
                                    colorScheme="primary"
                                    variant="outline"
                                    borderRadius="full"
                                    size={"lg"}
                                    fontSize="14px"
                                    fontWeight={"400"}
                                    onClick={downloadPDF}
                                    leftIcon={<img style={{ width: "18px", height: "18px" }} src={images.downloadArrow} />}>
                                    Descargar archivo con QR
                                </Button>
                            </Box>
                        </div>
                    }
                </ModalBody>

                <ModalFooter px="24px" py="16px">
                    <Button
                        colorScheme="primary"
                        variant="outline"
                        borderRadius="full"
                        mr={3}
                        px="32px"
                        size={"lg"}
                        py="8px"
                        fontWeight={"400"}
                        fontSize="14px"
                        onClick={onCloseModal}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="solid"
                        colorScheme={"primary"}
                        borderRadius="full"
                        px="64px"
                        py="8px"
                        fontSize="14px"
                        size={"lg"}
                        onClick={onPressNext}
                        disabled={!selectedOption && step === "options"}
                    >
                        Continuar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalFastPass;