import React, { useEffect, useRef, useState } from "react";
import { AdminViewProps, TableRefProps } from "./interfaces";
import { useAdminViewController } from "./admin-view.controller";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Center,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Switch,
  Tag,
  TagProps,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { HiUsers } from "react-icons/hi";
import { AiOutlinePercentage } from "react-icons/ai";
import CustomTable from "../../../../components/Table";
import { DeleteIcon } from "../../../../components/Icons/DeleteIcon";
import AddAdminForm from "./components/AddAdminform";
import { DiscountCodesTable } from "./components/DiscountCodesTable";
import { TicketsTable } from "./components/TicketsTables";
import { TicketIcon } from "../../../../components/Icons/TicketIcon";
import { QrIcon } from "../../../../components/Icons/QrIcon";
import { useLocation } from "react-router-dom";

export const AdminView: React.FC<AdminViewProps> = ({
  useController = useAdminViewController,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const controller = useController();
  const location = useLocation();
  const { data } = location.state || {}; //

  const tableRef = useRef<TableRefProps | null>(null);
  const propsStates: {
    state: string;
    text: string;
    props: TagProps;
  }[] = [
    {
      state: "active",
      text: "Activo",
      props: {
        colorScheme: "green",
      },
    },
    {
      state: "pending",
      text: "Pendiente",
      props: {
        colorScheme: "blue",
      },
    },
    {
      state: "paused",
      text: "Pausado",
      props: {
        colorScheme: "yellow",
      },
    },
    {
      state: "inactive",
      text: "Inactivo",
      props: {
        colorScheme: "red",
      },
    },
  ];

  const rolesArray: {
    label: string;
    value: string;
  }[] = [
    {
      label: "Administrador",
      value: "admin",
    },
    {
      label: "Solo lectura",
      value: "readOnly",
    },
  ];

  const columns = [
    {
      Header: "Alias",
      accessor: "alias",
      isCentered: true,
      Cell: ({
        row: {
          original: { email, alias },
        },
      }) => <Text>{alias ?? "-------"}</Text>,
    },
    {
      Header: "EMAIL",
      accessor: "email",
      isCentered: true,
    },
    {
      isCentered: true,
      Header: "PERMISOS",
      accessor: "role",
      Cell: ({
        row: {
          original: { role },
        },
      }) => (
        <Text>
          {rolesArray.find((roleItem) => roleItem.value === role)?.label ??
            rolesArray[0].label}
        </Text>
      ),
    },
    {
      Header: "ESTADO",
      accessor: "status",
      isCentered: true,
      Cell: ({
        row: {
          original: { status },
        },
      }) => {
        const stateProps =
          propsStates.find((item) => item.state === status) ??
          propsStates.at(-1);

        return (
          <Center>
            <Tag size={"md"} variant="subtle" {...stateProps.props}>
              {stateProps.text}
            </Tag>
          </Center>
        );
      },
    },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fastPass, setFastPass] = useState(false);

  const onChangeFastPass = async () => {
    if(controller?.event){
      const newValue = !fastPass;
      setFastPass(newValue);
      console.log("aca fast pass data",data)
      //return;
      controller.userEventsService.editEvent({...controller?.event,fastPass: newValue});
    }
  }

  useEffect(() => {
    if(controller?.event){
      setFastPass(controller?.event?.fastPass);
    }
  },[controller?.event])

  return (
    <Stack direction={"column"} spacing={7} pt="1.5%">
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius={20}
          backgroundColor={"backgroundColor"}
          borderWidth={2}
          borderColor={"secondary.main"}
          p={10}
        >
          <ModalCloseButton />
          <ModalBody>
            <AddAdminForm addMethod={controller.addAdmin} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Accordion
        w="full"
        allowToggle
        overflow={"hidden"}
        bgColor={"secondary.main"}
        color={"secondary.contrast"}
      >
        <AccordionItem w="full">
          <AccordionButton w="full" _expanded={{ color: "primary.main" }}>
            <HStack w="full" spacing={3}>
              <HiUsers fontSize={24} />
              <Text fontSize={"xl"} fontWeight="medium" noOfLines={1}>
                Usuarios
              </Text>
            </HStack>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Text fontSize={"sm"} noOfLines={1} mb={4}>
              Creá un token para usar con nuestra aplicación disponible y enviá la lista de acceso a tu staff.
            </Text>
            <CustomTable
              ref={tableRef}
              fetchData={controller.getUserList}
              searchProps={{
                inputProps: {
                  bgColor: "secondary.main",
                },
              }}
              customHeader={
                <Stack
                  direction={"row"}
                  spacing={2}
                  w={["full", "full", "85%"]}
                >
                  <Button
                    onClick={onOpen}
                    colorScheme={"primary"}
                    rounded="full"
                    variant={"outline"}
                  >
                    Añadir usuario
                  </Button>
                  <IconButton
                    aria-label="Call Segun"
                    size="md"
                    variant={"ghost"}
                    rounded={"full"}
                    alignSelf="self-start"
                    colorScheme={"primary"}
                    onClick={() => {
                      tableRef.current &&
                        controller.updateAdmins(
                          tableRef.current.tableInstance.selectedFlatRows.map(
                            (item) => item.values
                          ),
                          "delete"
                        );
                    }}
                    icon={<DeleteIcon />}
                  />
                </Stack>
              }
              columns={columns}
              data={controller.adminListData.data ?? []}
              loading={controller.userListLoading}
              totalDocs={controller.adminListData.totalDocs}
              showRowSelection
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton w="full" _expanded={{ color: "primary.main" }}>
            <HStack w="full" spacing={3}>
              <AiOutlinePercentage fontSize={24} />
              <Text fontSize={"xl"} fontWeight="medium" noOfLines={1}>
                Códigos de descuento
              </Text>
            </HStack>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <DiscountCodesTable />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton w="full" _expanded={{ color: "primary.main" }}>
            <HStack w="full" spacing={3}>
              <TicketIcon fontSize={24} />
              <Text fontSize={"xl"} fontWeight="medium" noOfLines={1}>
                Tickets
              </Text>
            </HStack>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <TicketsTable />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton w="full" _expanded={{ color: "primary.main" }}>
            <HStack w="full" spacing={3}>
              <QrIcon fontSize={24}/>
              <Text fontSize={"xl"} fontWeight="medium" noOfLines={1}>
              Fast Pass
              </Text>
            </HStack>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <HStack pl={9} flexDirection={"column"} alignItems={"flex-start"}>
              <Text m={0}>Habilita el Fast Pass para que las personas puedan comprar tickets en la puerta del evento escaneando un QR.</Text>
              <Text marginInlineStart={"0px !important"} ml={0}>Descarga el QR sólo una vez desde la pantalla ‘Mis eventos’ y utilízalo para cada evento con Fast Pass habilitado.</Text>
              <HStack marginInlineStart={"0px !important"} pt={6}>
              <Switch
                size="lg"
                colorScheme={"green"}
                onChange={onChangeFastPass}
                checked={fastPass}
                isChecked={fastPass}
              />
              <Text>{fastPass ? "Habilitado" : "Deshabilitado"}</Text>
              </HStack>
            </HStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};
