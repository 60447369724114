import { Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { QrIcon } from "../../../../components/Icons/QrIcon";
import QRCode from 'qrcode';

interface PaymentStatusProps{
    status?: "success" | "failure";
}

const PaymentStatus = ({status = "success"}: PaymentStatusProps) => {
    const [qr, setQr] = useState<any>();

    const generateQr = async () => {
        const qrImageUrl = await QRCode.toDataURL(`https://chileticket.com/fastPass`);
        setQr(qrImageUrl);
    };

    useEffect(() => {
        if(status === "success"){
            generateQr();
        }
    },[status])

    return(
        <Stack alignItems={"center"} justifyContent={"center"}>
            {
                status === "failure" ?
                <>
                    <Text fontWeight={"600"} fontSize={"24px"}>Hubo un error en el pago</Text>
                    <Text paddingLeft={"48px"} textAlign={"center"} paddingRight={"48px"} fontWeight={"400"} fontSize={"16px"}>
                        Vuelve a intentarlo o prueba con otro método de pago
                    </Text>
                </>
                :
                <>
                    <Text fontWeight={"600"} fontSize={"24px"}>¡Compraste tus tickets!</Text>
                    <Text margin={"16px 0 !important"} paddingLeft={"48px"} textAlign={"center"} paddingRight={"48px"} fontWeight={"400"} fontSize={"16px"}>
                        También puedes descargarlas desde tu mail
                    </Text>
                    <Text marginTop={"0px !important"} marginBottom={"16px !important"} fontWeight={"600"} fontSize={"24px"}>mlopez@gmail.com</Text>
                    <Stack padding={"16px 32px"} border={"1px solid rgba(170, 169, 169, 1)"} borderRadius={"16px"}>
                        <Text fontSize={"24px"} fontWeight={"600"}>Ticket1</Text>
                        <img src={qr} style={{ width: "277px", height: "277px" }} />
                        <Text>Sector: XXX</Text>
                        <Text>ID Ticket: xxxxx</Text>
                        <Text>Nombre de asistente: Mariano Lopez</Text>
                        <Text>RUT: -</Text>
                    </Stack>
                </>
            }
        </Stack>
    )
};

export default PaymentStatus;