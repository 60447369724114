import { Box, chakra } from "@chakra-ui/react";
import React from "react";

interface LandingLayoutProps {
  header: any;
  footer: any;
  children: any;
  center?: boolean;
}

const LandingLayout: React.FC<LandingLayoutProps> = ({
  header,
  footer,
  children,
  center
}) => {
  const url = window.location.href;
  const isChileTiket =
  url.startsWith("https://www.chiletiket") ||
  url.startsWith("https://chiletiket") ||
  url.startsWith("https://test.chiletiket") ||
  url.startsWith("http://localhost");

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      maxW={"100%"}
      minH={"100vh"}
      // overflow="auto"
      bgColor={"backgroundColor"}
    >
      <chakra.header
        as={Box}
        position={"fixed"}
        top="0"
        zIndex={"999"}
        width={"100%"}
      >
        {header}
      </chakra.header>
      <Box maxW={"100vw"} style={{display: center ? "flex" : "block" , justifyContent: "center", alignItems: "center"}} flexGrow={1} mt={isChileTiket ? 24 : 16}>
        {children}
      </Box>
      <chakra.footer>{footer}</chakra.footer>
    </Box>
  );
};

export default LandingLayout;
