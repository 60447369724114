import { useDisclosure, useInterval, useToast } from "@chakra-ui/react";
import { differenceInSeconds, set } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EventDTO } from "../../../services/events/dto";
import { useEventsService } from "../../../services/events/eventsService";
import { useProfileService } from "../../../services/profile/profileService";
import { ShoppingSessionDTO } from "../../../services/shoppingSession/dto";
import { useShoppingSessionService } from "../../../services/shoppingSession/shoppingSessionService";
import {
  formateDateAndHour,
  secondsToMinutesString,
} from "../../../utils/dateFormatters";
import { priceFormatter } from "../../../utils/priceFormat";
import { useAsync } from "../../../utils/useAsync";
import { PaymentSummaryController } from "./interfaces";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { paymentMethods } from "../../../config/constants";
import { useToaster } from "../../../utils/useToaster";


const mapEventDTO = (event: EventDTO, shoppingSession: ShoppingSessionDTO) => {
  if (event && shoppingSession) {
    console.log(
      shoppingSession.price?.discountMeta?.discountType &&
        shoppingSession.price?.discountMeta?.value
        ? (shoppingSession.price?.discountMeta?.discountType === "fixed"
          ? "$"
          : "") +
          shoppingSession.price?.discountMeta?.discountType ===
          "fixed"
          ? priceFormatter(shoppingSession.price?.discountMeta?.value)
          : shoppingSession.price?.discountMeta?.value?.toString() +
          (shoppingSession.price?.discountMeta?.discountType === "percentage"
            ? "%"
            : "") +
          " off"
        : undefined
    );

    return {
      imageURL: event.images?.card.url,
      title: event.name,
      description: event.description,
      location: event.placeName,
      date: formateDateAndHour(new Date(shoppingSession.function.date)),
      priceTotal: "$" + priceFormatter(shoppingSession.price.finalPrice),
      paymentMethods: event.paymentMethods,
      isDiscounted: Boolean(shoppingSession.price?.discount),
      originalPrice:
        shoppingSession.price?.discountMeta?.originalPrice &&
        "$" +
        priceFormatter(
          shoppingSession.price.discountMeta.originalPrice.finalPrice
        ),
      discountAmount:
        shoppingSession.price?.discountMeta?.discountType &&
          shoppingSession.price?.discountMeta?.value
          ? (shoppingSession.price?.discountMeta?.discountType === "fixed"
            ? "$"
            : "") +
            shoppingSession.price?.discountMeta?.discountType ===
            "fixed"
            ? priceFormatter(shoppingSession.price?.discountMeta?.value)
            : shoppingSession.price?.discountMeta?.value?.toString() +
            (shoppingSession.price?.discountMeta?.discountType ===
              "percentage"
              ? "%"
              : "") +
            " off"
          : undefined,
    };
  } else {
    return {
      imageURL: "",
      title: "",
      description: "",
      location: "",
      date: "",
      priceTotal: "",
      paymentMethods: [],
    };
  }
};

export const usePaymentSummaryController = (fastPass = false): PaymentSummaryController => {
  const eventsService = useEventsService();
  const profileService = useProfileService();
  console.log("usePaymentSummaryController fast pass",fastPass)
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const { id } = useParams();

  const {
    data: profileData,
    loading: profileLoading,
    execute: profileExecute,
  } = profileService.profileStore;

  useEffect(() => {
    if(!fastPass){
      profileExecute(profileService.getProfile);
    }
  }, [fastPass]);

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const [seconds, setSeconds] = useState(1200);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("pagoHub"); // Default to pagoHub
  const shoppingSessionService = useShoppingSessionService();
  const { data: shoppingSession, setData: setShoppingSessionData } =
    shoppingSessionService.storage;

  const {
    isOpen: isFormOpen,
    onClose: onFormClose,
    onOpen: onFormOpen,
  } = useDisclosure();

  const {
    isOpen: isDiscountOpen,
    onClose: onDiscountClose,
    onOpen: onDiscountOpen,
  } = useDisclosure();

  const [discount, setDiscount] = useState("");

  const { successToast } = useToaster();

  const [, discountError, discountLoading, discountExecute] = useAsync(
    shoppingSessionService.getDiscount,
    {
      onSuccess: (data) => {
        setShoppingSessionData(data);
        onDiscountClose();
        successToast(
          "Descuento aplicado con éxito!",
          `Tu cupón o código de descuento ${"de " +
          (data.price?.discountMeta?.discountType === "fixed" ? "$" : "") +
          data.price?.discountMeta?.value?.toString() +
          (data.price?.discountMeta?.discountType === "percentage"
            ? "%"
            : "") +
          " off"
          } ha sido aplicado correctamente.`
        );
      },
    }
  );

  const onDiscountSubmit = () => {
    discountExecute(discount, shoppingSession._id);
  };

  useInterval(
    () => setSeconds((seconds) => seconds - 1),
    seconds > 0 ? 1000 : null
  );

  useEffect(() => {
    if (seconds <= 0) {
      setSessionExpired(true);
    }
  }, [seconds]);

  const syncTimer = useCallback(async () => {
    if (shoppingSession) {
      try {
        console.log("synctimer faspass",fastPass)
        if(fastPass){
          const info = await shoppingSessionService.getFastPassShoppingSession(
            shoppingSession._id
          );
          setSeconds(Math.floor(info.leftTime));
        }else{
          const info = await shoppingSessionService.getShoppingSession(
            shoppingSession._id
          );
          setSeconds(Math.floor(info.leftTime));
        }
      } catch {
        if (shoppingSession.expirationTime) {
          const remainingSeconds = differenceInSeconds(
            new Date(shoppingSession.expirationTime),
            new Date()
          );
          setSeconds(remainingSeconds > 0 ? remainingSeconds : 0);
        }
      }
    }
  }, [shoppingSession]);

  useEffect(() => {
    window.addEventListener("focus", syncTimer);
    return () => {
      window.removeEventListener("focus", syncTimer);
    };
  }, []);

  useEffect(() => {
    syncTimer();
  }, [shoppingSession]);

  const [event, , eventLoading, eventExecute] = useAsync(
    eventsService.getEvent
  );
  
  const mappedEvent = useMemo(
    () => mapEventDTO(event, shoppingSession),
    [event, shoppingSession]
  );

  useEffect(() => {
    if (event && event.paymentMethods && event.paymentMethods.length === 1) {
      if (mappedEvent.paymentMethods[0] === "PH") {
        setSelectedMethod("pagoHub");
      }
      if (mappedEvent.paymentMethods[0] === "MP") {
        setSelectedMethod("mercadoPago");
      }
      if (mappedEvent.paymentMethods[0] === "FL") {
        setSelectedMethod("flow");
      }
    }
  }, [event]);

  useEffect(() => {
    if (id) {
      eventExecute(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onBuy = () => {
    if (!fastPass && (!profileData || !profileData.idNumber || !profileData.birthday)) {
      handleReCaptchaVerify();
      onFormOpen();
      return;
    }
    if(fastPass){
      onFastPassPayment()
    }else{
      onPayment();
    }
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const [token, setToken] = useState<string>(undefined);
  const toast = useToast();

  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      const captcha = await executeRecaptcha("action");
      if (captcha) {
        setToken(captcha);
      }
      return;
    }
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleMethodChange = (value: string) => {
    setSelectedMethod(value);
  };

  const onPayment = async () => {
    try {
      setIsPaymentLoading(true);

      const paymentMethod = paymentMethods[selectedMethod];

      if (paymentMethod) {
        const res = await shoppingSessionService.getShoppingSessionPayment(
          shoppingSession._id,
          paymentMethod
        );
        window.location.href = res.url;
      } else {
        toast({
          title: "Error al realizar el pago",
          description: "Selecciona una forma de pago",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "Error al realizar el pago",
        description: "Ocurrió un error al realizar el pago.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const onFastPassPayment = async () => {
    try {
      setIsPaymentLoading(true);

      const paymentMethod = paymentMethods[selectedMethod];

      if (paymentMethod) {
        const res = await shoppingSessionService.getFastPassShoppingSessionPayment(
          shoppingSession._id,
          paymentMethod
        );
        window.location.href = res.url;
      } else {
        toast({
          title: "Error al realizar el pago",
          description: "Selecciona una forma de pago",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "Error al realizar el pago",
        description: "Ocurrió un error al realizar el pago.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsPaymentLoading(false);
    }
  }

  return {
    ...mappedEvent,
    eventLoading: eventLoading || profileLoading,
    timer: secondsToMinutesString(seconds),
    onPayment,
    onBuy,
    isPaymentLoading,
    isFormOpen,
    onFormClose,
    sessionExpired,
    setSelectedMethod,
    selectedMethod,
    handleMethodChange,
    isDiscountOpen,
    onDiscountClose,
    onDiscountOpen,
    discount,
    setDiscount,
    onDiscountSubmit,
    discountError,
    discountLoading,
  };
};
