import React, { useEffect, useState } from "react";
import { EventDetailProps } from "./interfaces";
import { useEventDetailController } from "./event-detail.controller";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import ModalContainer from "../../../components/LoginModal";
import SeatsLayout from "./DetailLayouts/SeatsLayout";
import TicketsLayout from "./DetailLayouts/TicketsLayout";
import MapLayout from "./DetailLayouts/MapLayout";
import EventStatusCard from "./components/EventStatusCard";
import SeatsLayoutMobile from "./DetailLayouts/SeatsLayoutMobile";
import EventInfo from "./components/event-info";
import QueueLayout from "./DetailLayouts/QueueLayout";
import { useLocation } from "react-router-dom";
import { priceFormatter } from "../../../utils/priceFormat";

export const EventDetail: React.FC<EventDetailProps> = ({
  useController = useEventDetailController,
  fastPass
}) => {
  console.log("Event detail fastPass",fastPass)
  const controller = useController(fastPass);
  const [isFastPass, setIsFastPass] = useState(false);
  const { isOpen, onClose, onOpen } = controller.modalController;

  const isMobile = useBreakpointValue<boolean>({
    base: true,
    md: false,
  });

  const hasSeats = controller.currentChartKey;

  const hasQueue = controller.hasQueue;
  const location = useLocation();

  useEffect(() => {
    const chatElement = document.getElementById('OmuniWebChat');
    if(location.pathname.includes("/fastPass/")){
      setIsFastPass(true);
      if (chatElement) {
        chatElement.style.display = 'none';
        return;
      }
      return;
    }else{
      setIsFastPass(false);
      if (chatElement) {
        chatElement.style.display = 'block';
        return;
      }
    }
    return () => {
      setIsFastPass(false);
      if (chatElement) {
        chatElement.style.display = 'block';
        return;
      }
    }
  }, [location.pathname]);
  console.log("controller.eventStatus",controller.eventStatus)
  return (
    <Box h={"max-content"}>
      <ModalContainer isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

      {controller.eventStatus === "Published" ? (
        !hasQueue ? (
          hasSeats ? (
            isMobile ? (
              <SeatsLayoutMobile controller={controller} />
            ) : (
              <SeatsLayout controller={controller} />
            )
          ) : controller.mapImageURL ? (
            <MapLayout controller={controller} />
          ) : (
            <TicketsLayout controller={controller} />
          )
        ) : (
          <QueueLayout controller={controller} />
        )
      ) : (
        <>
          <EventInfo controller={controller} />
          <EventStatusCard
            eventStatus={controller.eventStatus}
            eventStatusMeta={controller.eventStatusMeta}
          />
        </>
      )}
    </Box>
  );
};
