import { useContext } from "react";
import { AxiosContext } from "../../providers/axiosContext";
import { AsyncStore } from "../../utils/createAsyncStore";
import {
  EventDTO,
  EventsPageDTO,
  EventTicketVariants,
  TicketVariantDTO,
  SearchResponseDTO,
  TurnDTO,
} from "./dto";
import { useEventsAsyncStore } from "./eventStore";
import { sleep } from "../../utils/sleep";
import { addHours } from "date-fns";
import { getProducer } from "../../utils/getProducer";

export interface EventsService {
  getEventList: () => Promise<EventDTO[]>;
  getEvent: (eventId: string) => Promise<EventDTO>;
  getTicketVariants: (eventId: string) => Promise<EventTicketVariants>;
  getFunctionTicketVariants: (
    functionId: string
  ) => Promise<TicketVariantDTO[]>;
  eventStore: AsyncStore<EventDTO[]>;
  getPlace: (placeId: string) => Promise<any>;
  searchEvents: (searchString: string) => Promise<SearchResponseDTO>;
  getTurn: () => Promise<TurnDTO>;
  getFastPassEventList: () =>  Promise<any>;
}

export const useEventsService = (): EventsService => {
  const axiosContext = useContext(AxiosContext);

  const eventStore = useEventsAsyncStore();

  const producer = getProducer();
//TODO agregar fastPass
  const getEventList = async () => {
    const res = await axiosContext.publicAxios.get<EventsPageDTO>(
      "/events/events",
      {
        params: {
          page: 1,
          limit: 50,
          sort: "datetime",
          producer,
        },
      }
    );

    return res.data.docs;
  };

  const getFastPassEventList = async () => {
    const res = await axiosContext.publicAxios.get<EventsPageDTO>(
      "/events/events",
      {
        params: {
          page: 1,
          limit: 50,
          sort: "datetime",
          producer: producer ? producer : "chiletiket",
          fastPass: true
        },
      }
    );

    return res.data.docs;
  };

  const getEvent = async (eventId: string) => {
    //Find event in store

    /* Condicion false por error en renderizado: Al usar este metodo del service el 
       estado en el controller no actualiza el state y causa mal renderizado.

       Solucion temporal: Desactivar el store y hacer fetch a endpoint
    */
    if (eventStore.data && false) {
      const event = eventStore.data.find((event) => event._id === eventId);
      if (event) {
        return event;
      }
    }

    //Fetch missing event
    const res = await axiosContext.publicAxios.get<EventDTO>(
      `/events/events/${eventId}`
    );
    return res.data;
  };

  const getTicketVariants = async (
    eventId: string
  ): Promise<EventTicketVariants> => {
    const res = await axiosContext.publicAxios.get<EventTicketVariants>(
      `tickets/ticket-variant/event/${eventId}`,
      { params: { full: true } }
    );

    return res.data;
  };

  const getFunctionTicketVariants = async (
    functionId: string
  ): Promise<TicketVariantDTO[]> => {
    const res = await axiosContext.publicAxios.get<TicketVariantDTO[]>(
      `tickets/ticket-variant/function/${functionId}`,
      { params: { full: true } }
    );
    return res.data;
  };

  const getPlace = async (placeId: string) => {
    const res = await axiosContext.publicAxios.get(`/events/places/${placeId}`);
    return res.data;
  };

  const searchEvents = async (searchString: string) => {
    const res = await axiosContext.publicAxios.get("/events/events/search", {
      params: {
        name: searchString,
        producer,
      },
    });
    // console.log("Res: ", res);

    return res.data;
  };

  const getTurn = async () => {
    const res = await axiosContext.authAxios.get(
      `https://global-tickets-events-test-zhnk3pvbra-uc.a.run.app/events/events/turn`
    );
    return res.data;
  };

  return {
    getEventList,
    getEvent,
    getTicketVariants,
    getFunctionTicketVariants,
    eventStore,
    getPlace,
    searchEvents,
    getTurn,
    getFastPassEventList
  };
};
