import { Center, Spinner } from "@chakra-ui/react";
import { Navigate, RouteObject, useRouteError } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import AdminLayoutContainer, {
  EventListLayoutContainer,
  EventInfoLayout,
  StatsLayoutContainer,
  AssistanceLayoutContainer,
  OfficeSaleLayout,
} from "../layouts/AdminLayout";
import LandingLayoutContainer, {
  EventDetailLayoutContainer,
  PaymentSummaryLayoutContainer,
  ProfileLayoutContainer,
  EventStepperFormLayoutContainer,
  TermsLayoutContainer,
  ErrorLayoutContainer,
  TurnSessionLayoutContainer,
  FrequentLayoutContainer,
  FastPassLandingLayoutContainer,
  PersonalDataFastPassLayoutContainer,
  PaymentStatusLayoutContainer,
} from "../layouts/LandingLayout";
import { AdminView } from "../views/Admin/EventInfo/Admin/admin-view.view";
import { TokenView } from "../views/Admin/EventInfo/Token/token.view";
import { saleStepperRoutes } from "../views/Admin/OfficeSale/routes";

const Loader = () => (
  <Center w="100%" h="100vh">
    <Spinner m="auto" />
  </Center>
);

export const AdminRoutes = [
  {
    path: "stats",
    title: "Ventas y estadisticas",
    element: <PrivateRoute component={StatsLayoutContainer} />,
  },
  {
    path: "assistance",
    title: "Asistencia",
    element: <PrivateRoute component={AssistanceLayoutContainer} />,
  },
  {
    path: "admin",
    title: "Administración",
    element: <PrivateRoute component={AdminView} />,
  },
  {
    path: "token",
    title: "Generar Token",
    element: <PrivateRoute component={TokenView} />,
  },
];

const ErrorHandlerElement = () => {
  let error = useRouteError();
  console.error(error);
  return <ErrorLayoutContainer />;
};

export const RoutesObject: RouteObject[] = [
  {
    path: "/",
    element: <LandingLayoutContainer />,
    loader: Loader,
  },
  {
    path: "/fastPass/",
    element: <FastPassLandingLayoutContainer/>,
    loader: Loader,
  },
  {
    path: "/fastPass/events/:id",
    element: <EventDetailLayoutContainer fastPass={true} />,
    loader: Loader,
    errorElement: <ErrorHandlerElement />,
  },
  {
    path: "fastPass/events/:id/personalData",
    element: <PersonalDataFastPassLayoutContainer/>,
    loader: Loader,
  },
  {
    path: "fastPass/events/:id/personalData/payment/status",
    element: <PaymentStatusLayoutContainer/>,
    loader: Loader,
  },
  {
    path: "fastPass/events/:id/personalData/payment",
    element: <PaymentSummaryLayoutContainer fastPass={true}/>,
    loader: Loader,
  },
  {
    path: "fastPass/events/:id/payment",
    element: <PaymentSummaryLayoutContainer fastPass={true}/>,
    loader: Loader,
  },
  {
    path: "/events/:id",
    element: <EventDetailLayoutContainer />,
    loader: Loader,
    errorElement: <ErrorHandlerElement />,
  },
  {
    path: "/turn",
    element: <PrivateRoute component={TurnSessionLayoutContainer} />,
    loader: Loader,
    errorElement: <ErrorHandlerElement />,
  },
  {
    path: "/events/:id/payment",
    element: <PrivateRoute component={PaymentSummaryLayoutContainer} />,
    loader: Loader,
  },
  {
    path: "/profile",
    element: <PrivateRoute component={ProfileLayoutContainer} />,
    loader: Loader,
  },
  {
    path: "/admin",
    element: <AdminLayoutContainer />,
    loader: Loader,
    children: [
      {
        index: true,
        element: <PrivateRoute component={EventListLayoutContainer} />,
      },
      {
        path: ":id",
        children: [
          {
            path: "info",
            element: <PrivateRoute component={EventInfoLayout} />,
            children: [
              ...AdminRoutes,
              {
                index: true,
                element: <Navigate to={"stats"} />,
              },
            ],
          },
          {
            path: "sale",
            element: <PrivateRoute component={OfficeSaleLayout} />,
            loader: Loader,
            children: saleStepperRoutes,
          },
          {
            index: true,
            element: <Navigate to="/admin" />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to={"/admin"} />,
      },
    ],
  },
  {
    path: "/create-event",
    element: <EventStepperFormLayoutContainer />,
    loader: Loader,
  },
  {
    path: "/terms",
    element: <TermsLayoutContainer />,
    loader: Loader,
  },
  {
    path: "/frequents",
    element: <FrequentLayoutContainer />,
    loader: Loader,
  },
  {
    // Fallback route - No Match redirect to landing ( " / " )
    path: "*",
    element: <Navigate to={"/"} />,
  },
];
